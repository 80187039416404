import React from "react"
import { graphql } from "gatsby"
import { Controller, Scene } from "react-scrollmagic"
import TransitionLink from "gatsby-plugin-transition-link"

import MenuLayout from "../components/menulayout"
import SEO from "../components/seo"
import Thumb from "../components/thumb"

const IndexPage = ({ data }) => {
  let duration
  if (typeof window !== `undefined`) {
    duration = window.innerHeight * 1.6
  }

  return (
    <MenuLayout>
      <SEO title="Visuals — Grafik — Design" />
      <div className="home">
        <section className="row">
          <article className="col-xs-12 col-sm-12 col-md-12 right">
            <hr />
            <div className="keyvisual">
              <h1>Rivic Visuals.</h1>
              <h2>
                Ich bin selbständiger visueller Designer.
                <br />
                Ich verwirkliche Ihre Projekte und Ideen in professionellen
                Designs für Apps, Webseiten, Tools auf den unterschiedlichsten
                Screens.
              </h2>
            </div>
          </article>
        </section>
        <section id="benefits" className="row">
          <Controller
            globalSceneOptions={{
              duration: duration,
              triggerElement: "#benefits",
            }}
          >
            <Scene classToggle="active">
              <div className="col-sm-4 card-holder" role="navigation">
                <div className="card">
                  <h3>Lösungen</h3>
                  <h4>Finden und Verbinden.</h4>
                  <p>
                    Im Visuellen Design geht es um das Finden von Lösungen für
                    Problemstellungen, die sich in der Entwicklung jedes
                    Projektes zeigen. Tiefes interessengetriebenes Verständnis
                    vom jeweiligen Projektthema, des Umfelds und der besonderen
                    Anforderungen, ermöglichen es mir, zielstrebig Varianten und
                    Ideen durchzuspielen.
                  </p>
                  <p>
                    So finden wir gemeinsam die beste Lösung für alle
                    beteiligten. Auch bei einer Anhäufung von Hindernissen
                    behalte ich einen kühlen Kopf und suche fokussiert weitere
                    Ansätze.
                  </p>
                  <p>
                    Gewissenhaft — Empathisch <br />
                    Neugierig — Flexibel <br />
                    Zielstrebig
                  </p>
                </div>
              </div>
            </Scene>
            <Scene offset={duration} classToggle="active">
              <div className="col-sm-4 card-holder" role="navigation">
                <div className="card">
                  <h3>Professionell</h3>
                  <h4>
                    Planen, fokussieren, <br />
                    ausführen.
                  </h4>
                  <p></p>
                  <p>
                    Die Visuals die Sie von mir bekommen, nehmen Rücksicht auf
                    unzählige Aspekte, Regeln und Konventionen, um eine hohe
                    Qualität zu wahren. Menschen, die mit den Visuals in Kontakt
                    kommen, erhalten optimierte Kommunikation, die auf
                    menschliche kognitive Eigenheiten abgestimmt sind.
                  </p>
                  <p>
                    Mir ist besonders wichtig, kein Detail undurchdacht zu
                    lassen, damit Ihr Projekt eine klare Hierarchie, schnelle
                    Erfassbarkeit und ein professionelles Erscheinungsbild
                    erhält.
                  </p>
                  <p>
                    Detailliert — Fokussiert <br />
                    Durchdacht — Sauber <br />
                    Verständlich
                  </p>
                </div>
              </div>
            </Scene>
            <Scene offset={duration * 2} classToggle="active">
              <div className="col-sm-4 card-holder" role="navigation">
                <div className="card">
                  <h3>Teamarbeit</h3>
                  <h4>Gemeinsam ans Ziel.</h4>
                  <p>
                    Als externer Partner verstehe ich es, engen Kontakt mit
                    Ihnen und Ihrem Team zu halten. In der Rolle des visuellen
                    Designers trage ich die Verantwortung, übergreifende
                    Inhalte, Informationen und Anforderungen im Wechselspiel mit
                    Ihrem Projektteam zu sortieren, diskutieren und
                    aufzubereiten, um an zufrieden stellende Ergebnisse zu
                    kommen.
                  </p>
                  <p>
                    Ich erwarte einen konstruktiven Austausch, denn wir arbeiten
                    an einem gemeinsamen Ziel, und so können Sie von mir
                    Zuverlässigkeit, Pünktlichkeit und selbstverständlich
                    diskrete Behandlung der Projektmaterialien erwarten.
                  </p>
                  <p>
                    Kommunikativ — Pflichtbewusst <br />
                    Ordentlich — Zuverlässig <br />
                    Übergreifend
                  </p>
                </div>
              </div>
            </Scene>
          </Controller>
        </section>
        <section className="row">
          <TransitionLink to="/leistungen" className="col-centered">
            <h4
              className="animated"
              style={{ display: `inline-block`, marginRight: 24, fontSize: 28 }}
            >
              Weitere Leistungen
            </h4>
            <img
              src="/img/arrow-right.svg"
              alt="Pfeil nach Rechts"
              style={{
                objectFit: `contain`,
                display: `inline-block`,
                width: `unset`,
                verticalAlign: `sub`,
              }}
            />
          </TransitionLink>
        </section>
        <section id="bio" className="row">
          <div className="col-xs-12 col-sm-6 col-centered">
            <h3>
              Persönlichkeit <br />
              &amp; Ansporn
            </h3>
            <p>
              Meine Arbeit ist getrieben durch das Bedürfnis, neues zu lernen,
              Abläufe zu verstehen und Hilfestellungen anzubieten. Im visuellen
              Design kann ich meine Interessen in zusammenhängenden,
              informativen und nützlichen Produkten verbinden.
            </p>
            <p>
              Im Status eines Freelancers betreue ich mit jahrelanger Erfahrung
              und laufender Weiterbildung abwechslungsreiche Kunden und ihre
              Projekte. Mein Zugang von Außen mit frischen Augen lässt sie
              indirekt voneinander profitieren.
            </p>
            <p>
              Projekte an denen ich bisher gearbeitet habe, brachte ich mit dem
              jeweiligen Team bis zu einer hohen Zufriedenheit des
              Auftraggebers, und trage dazu bei, Ideen greifbar zu machen und
              konkrete Lösungen zu schaffen.
            </p>
          </div>
        </section>

        <section id="projekte" className="row">
          <div className="col-xs-12 col-sm-6 col-centered">
            <h3>Projekte</h3>
          </div>
          <div id="projekte-loop" className="col-xs-12 col-sm-12">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <Thumb node={node} key={node.id} colSpan={4} />
            ))}
          </div>
        </section>

        <section id="clients" className="row">
          <div className="col-xs-12 col-sm-6 col-centered">
            <h3>
              Mitgewirkt bei
              <br />
              diesen Unternehmen
            </h3>
            <img
              src="./img/sclable.svg"
              alt="Logo Sclable"
              className="col-xs-4"
            />
            <img
              src="./img/allianz.svg"
              alt="Logo Allianz"
              className="col-xs-4"
            />
            <img
              src="./img/zuehlke.svg"
              alt="Logo Zühlke"
              className="col-xs-4"
            />
            <img src="./img/beach.svg" alt="Logo Beach" className="col-xs-4" />
            <img
              src="./img/ubimet.svg"
              alt="Logo Ubimet"
              className="col-xs-4"
            />
            <img src="./img/mcaps.svg" alt="Logo MCAPS" className="col-xs-4" />
          </div>
        </section>

        <section id="contact" className="row">
          <div className="col-xs-12 col-sm-6 col-centered">
            <h3>
              Ich möchte mit <br />
              Rivic Visuals an <br />
              einer Idee, einer App, einer Website zusammenarbeiten.
            </h3>
            <p>
              Schreiben Sie mir. Oder finden Sie mich auf LinkedIn. Ich habe
              immer ein offenes Ohr für neue Heraus&shy;forderungen.
            </p>
            <span className="mega mail animated">
              <a href="mailto:luka@rivic.at">luka@rivic.at</a>
            </span>
            <span className="mega phone animated">
              +43&thinsp;660&thinsp;575&thinsp;46&thinsp;45
            </span>
          </div>
        </section>
      </div>
    </MenuLayout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { status: { eq: "publish" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            status
            thumb {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
